import React from 'react';
import { ModalProvider } from 'design-system/src/hooks/useModal';
import 'design-system/src/scss/main.scss';

import locales from './locales.json';

export { wrapRootElement } from './gatsby-shared';

const LUX_PATH_LABELS = {
    '/es/': 'Home',
    '/en/': 'Home',
    '/es/comunidad/': 'Blog Home',
};

const LUX_PATH_BLOG_POST_REGEX =
    /\/(es|en)\/(comunidad|innovacion-personas|innovation-people)\/[\w-]+\/[\w-]+/;

function getNewRouteLang(pathname) {
    if (!pathname) {
        return null;
    }

    let parts = pathname?.split('/').filter((i) => !!i);

    if (!parts.length) {
        return null;
    }

    if (locales.indexOf(parts[0]) !== -1) {
        return parts[0];
    }

    return null;
}

export const onRouteUpdate = ({ location, prevLocation }) => {
    const routeLang = getNewRouteLang(location.pathname);

    // Set cookie with new language
    if (routeLang) {
        window.document.cookie = `pr_lng=${routeLang}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
    }

    if (window._hsq && prevLocation?.pathname && location.pathname) {
        window._hsq.push(['setPath', location.pathname]);
        window._hsq.push(['trackPageView']);
    }
};

// Wrap page element with ModalProvider
export const wrapPageElement = ({ element, props }) => {
    return <ModalProvider>{element}</ModalProvider>;
};

export const onClientEntry = () => {
    let label = window.location.pathname;

    if (LUX_PATH_LABELS[label]) {
        label = LUX_PATH_LABELS[label];
    } else if (LUX_PATH_BLOG_POST_REGEX.test(label)) {
        label = 'Blog Post';
    }

    const LUX = window.LUX || {};
    LUX.label = label;

    // Add GTM script
    if (process.env.GATSBY_GTM_ID) {
        var _hsp = window._hsp = window._hsp || [];
        _hsp.push(['addPrivacyConsentListener', (consent) => {
            if (consent.categories.analytics) {
                // Initialize GTM
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

                const script = document.createElement('script');
                script.async = true;
                script.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.GATSBY_GTM_ID}`;
                document.head.appendChild(script);
            }
        }]);
    }
};
