import React, { useState, useEffect, useRef, useCallback } from "react";

import styles from "./styles.module.scss";

let globalId = 0;

if (typeof window !== "undefined") {
  window.jQuery =
    window.jQuery ||
    function () {
      return {
        serializeArray: () => {},
        dispatchEvent: () => {},
      };
    };
}

export interface HubspotFormProps {
  formId: string;
  portalId: string;
  region: string;
  loading?: React.ReactNode;
  submitBlock?: boolean;
  onReady?: (ref: HTMLDivElement | null) => void;
  onSubmit?: (data: { [key: string]: any }) => void;
  blockedDomains?: string[];
  className?: string;
  submitDisabled?: boolean;
}

const HubspotForm: React.FC<HubspotFormProps> = ({
  loading,
  onReady,
  onSubmit,
  submitDisabled = false,
  submitBlock,
  blockedDomains,
  className,
  ...props
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState(false);
  const [isMounted, setMounted] = useState(false);
  const scriptLoadedRef = useRef(false);

  const loadHubspotScript = useCallback(() => {
    if (
      typeof window === "undefined" ||
      scriptLoadedRef.current ||
      window.hbspt?.forms
    ) {
      return Promise.resolve();
    }

    return new Promise<void>((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://js.hsforms.net/forms/v2.js";
      script.async = true;
      script.onload = () => {
        scriptLoadedRef.current = true;
        resolve();
      };
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }, []);

  useEffect(() => {
    if (scriptLoadedRef.current) {
      setMounted(true);
    } else {
      loadHubspotScript()
        .then(() => setMounted(true))
        .catch(console.error);
    }
  }, [loadHubspotScript]);

  const [id, setId] = useState<string>();

  let touched = useRef<{ [key: string]: any }>({}).current;

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    globalId++;
    setId(`reactHubspotForm${globalId}`);
  }, [setId]);

  const setupSubmitDisabled = useCallback(
    (value) => {
      if (!elementRef.current) {
        return;
      }

      let input: HTMLInputElement | null = elementRef.current.querySelector(
        'input[type="submit"]'
      );

      if (input) {
        input.disabled = value;
        if (input.parentNode) {
          input.parentNode.dataset.disabled = value;
        }
      }
    },
    [elementRef]
  );

  const onFormReady = useCallback(() => {
    if (!elementRef.current) {
      return;
    }

    let inputs =
      [
        ...elementRef.current.querySelectorAll(
          'input[type="text"],input[type="email"],input[type="tel"],input[type="number"],input[type="date"],textarea'
        ),
      ] ?? [];

    const onFocus = (e: Event) => {
      const target = e?.target as HTMLInputElement;

      if (target?.dataset?.error) {
        delete target.dataset.error;
        const item = target.parentNode?.parentNode;
        const label = item?.querySelector("[data-error-message]");

        if (label) {
          label.remove();
        }

        setupSubmitDisabled(false);
      }
    };

    const onBlur = (e: Event) => {
      const target = e?.target as HTMLInputElement;

      // eslint-disable-next-line react-hooks/exhaustive-deps
      touched = {
        ...touched,
        [target.name]: true,
      };

      if (touched?.[target.name as string]) {
        setTimeout(() => {
          const disabled = inputs.some((input) => {
            return input.className.includes("hubspot-field-with-error");
          });

          setupSubmitDisabled(disabled);
          // wait to error className to be applied
        }, 10);
        return;
      }
    };

    inputs.forEach((input) => {
      input?.addEventListener("focus", onFocus);
      input?.addEventListener("blur", onBlur);
    });

    if (elementRef?.current?.childNodes[0]) {
      elementRef?.current?.childNodes[0].addEventListener("submit", (ev) => {
        const disabled = inputs.some((input) =>
          input.className.includes("hubspot-field-with-error")
        );
        setupSubmitDisabled(disabled);
      });
    }

    setupSubmitDisabled(submitDisabled);
  }, [elementRef, submitDisabled, touched]);

  useEffect(() => {
    if (id && isMounted) {
      const createForm = () => {
        if (!window.hbspt || !elementRef.current) {
          return;
        }

        let options = {
          blockedDomains: blockedDomains ? blockedDomains : [],
          errorClass: `${styles["with-error"]} hubspot-field-with-error`,
          errorMessageClass: styles["error-message"],
          submitButtonClass: styles.submit,
          target: `#${id}`,
          onFormSubmit: ($form: any) => {
            let formData = $form.serializeArray();
            if (onSubmit) {
              onSubmit(formData);
            }
          },
          onFormReady: () => {
            setLoaded(true);
            onFormReady();
            if (onReady) {
              onReady(elementRef.current);
            }
          },
          ...props,
        };

        window.hbspt.forms.create(options);
      };

      const startCreateForm = () => {
        createForm();
      };

      if (!loaded) {
        startCreateForm();
      }
    }
  }, [
    id,
    elementRef,
    props,
    onSubmit,
    onReady,
    blockedDomains,
    isMounted,
    loaded,
    onFormReady,
  ]);

  useEffect(() => {
    setupSubmitDisabled(submitDisabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitDisabled]);

  return id ? (
    <>
      <div
        className={[
          styles.form,
          submitDisabled ? styles["form--submit-disabled"] : "",
          submitBlock ? styles["form--submit-block"] : "",
          className ? className : "",
        ].join(" ")}
        ref={elementRef}
        id={id}
        style={{ display: loaded ? "block" : "none" }}
      />
      {!loaded && loading ? loading : null}
    </>
  ) : loading ? (
    <>{loading}</>
  ) : null;
};

export default HubspotForm;
