import React from 'react';
import { Script } from 'gatsby'

export const wrapRootElement = ({ element }) => {
    return (
        <>
            {!!process.env.GATSBY_HUBSPOT_SCRIPT_ID && (
                <Script
                    id="hs-script-loader"
                    src={`https://js-eu1.hs-scripts.com/${process.env.GATSBY_HUBSPOT_SCRIPT_ID}.js`}
                />
            )}
            {element}
        </>
    )
}