import React from 'react';

import { Link as GatsbyLink, navigate as gatsbyNavigate } from 'gatsby';
import { NavigateFn, NavigateOptions } from '@reach/router';

import BaseLink, { BaseLinkProps } from '@components/BaseLink';

import { cls } from '@scss';

import { withSolutoPrefix } from '@utils/link';

interface LinkProps extends BaseLinkProps {
    replace?: boolean;
    [key: string]: any;
}

export default function Link({
    href,
    children,
    download,
    classes,
    className,
    ...props
}: LinkProps) {
    const to = typeof href === 'string' ? withSolutoPrefix(href) : undefined;

    if (!to) {
        return children;
    }

    const internal = to ? /^\/(?!\/)/.test(to) : false;

    if (internal) {
        if (download) {
            return (
                <BaseLink
                    href={to}
                    active={props?.active || props?.partiallyActive}
                    className={cls(classes?.root, className)}
                    download={download}
                    {...props}>
                    {children}
                </BaseLink>
            );
        }

        return (
            <GatsbyLink
                partiallyActive={props?.partiallyActive}
                to={to}
                className={cls(classes?.root, className)}
                activeClassName={classes?.active}
                replace={props?.replace}
                target={props?.target}
                {...props}
                {...(!!props.onClick && { onClick: props.onClick })}>
                {children}
            </GatsbyLink>
        );
    }

    return (
        <BaseLink
            href={to}
            active={props?.active || props?.partiallyActive}
            className={cls(classes?.root, className)}
            download={download}
            {...props}>
            {children}
        </BaseLink>
    );
}

export const navigate: NavigateFn = (...args) =>
    gatsbyNavigate(args[0] as string, args[1] as NavigateOptions<{}>);
